import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonWrapperComponent } from '../../components/button-wrapper/button-wrapper.component';
import { InputFieldsComponent } from '../../components/input-fields/input-fields.component';
import { createPasswordDto } from '../../dto/signin-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { GeneralEnum } from '../../constant/general-enum.enum';
import { ToastService } from '../../toast';
import { Images } from '../../constant/image';
import { icons } from '../../constant/icons';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [CommonModule, ButtonWrapperComponent, InputFieldsComponent],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent {
  public images = Images;
  public icons = icons;
  
  copyrightYear: any = new Date();
  activationCode!:string ;
  userId!:string ;
  confirmPassword: string = '';
  password: string = '';
  errorCred:any;
  createpasswordDto = new createPasswordDto;
  role: string = '';
  userRole = GeneralEnum;

  createPass = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
  },
  {
    validators: this.matchValidator('password', 'confirmPassword')
  }
);
 
 
   constructor(private authService: AuthService, private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private toastService:ToastService){
 
   }
 
   ngOnInit(){
    this.route.params.subscribe((data) => {
      this.activationCode = data["activationCode"];
      this.userId = data["userId"];
      this.role = data["role"];
      // this.showPreloader = false;
      if(this.activationCode && this.userId){
        this.activateUser();
      }
    });
   }

   matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
     return (abstractControl: AbstractControl) => {
         const control = abstractControl.get(controlName);
         const matchingControl = abstractControl.get(matchingControlName);
 
         if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
             return null;
         }
 
         if (control!.value !== matchingControl!.value) {
           const error = { confirmedValidator: 'Passwords do not match.' };
           this.errorCred =  error.confirmedValidator;
           matchingControl!.setErrors(error);
           return error;
         } else {
          this.errorCred =  '';
           matchingControl!.setErrors(null);
           return null;
         }
     }
   }

   getformdata(event:any, name:any){
     // event?.stopPropagation();
     if(name == 'password'){
       this.password = event;
     }
     if(name == 'confirmpassword'){
       this.confirmPassword = event;
     }
   }
 
   changepassword(){
      if(this.createPass.valid){
        const passwordData = {
          token: this.activationCode,
          newPassword: this.password
        };
        this.createpasswordDto = passwordData;
        // console.log(this.siginDto)
        this.authService.createPassword(this.createpasswordDto).subscribe({
          next: (data: any) => {
            if(this.role === this.userRole.central_admin || this.role === this.userRole.state_admin ){
              this.toastService.setSuccessMessage('Password created successfully', 'Sign in to your account')
              this.router.navigate(["/signin"]);
            }else{
              this.router.navigate(["/download-app"]);
            }
          },
          error: (error:HttpErrorResponse) => {
            this.toastService.setErrorMessage(error.error.message);
            this.errorHandler(error);
          },
        });
      }
   }
 
   activateUser(){
     // console.log(this.siginDto)
     this.authService.activateuser(this.userId, this.activationCode).subscribe({
       next: (data: any) => {
         console.log(data);
       },
       error: (error:HttpErrorResponse) => {
         this.toastService.setErrorMessage(error.error.message);
         this.errorHandler(error);
       },
     });
   }
 
   errorHandler(error: HttpErrorResponse) {
     // return this.toastService.setError(GeneralUtil.errorHandler(error));
     this.authService.errorHandler(error);
   }

}
