@if(inputData?.message){
  <div class="toast-container" 
    [ngClass]="
    inputData?.position == 'leftTop'
    ? 'toast-container leftTop'
    : inputData?.position == 'rightTop'
    ? 'toast-container rightTop'
    : inputData?.position == 'leftBottom'
    ? 'toast-container leftBottom'
    : 'toast-container rightBottom'"
  > 
    <div class="inner-container" >
      <div class="toast-body">
        <div class="inner-body-div">
          <span class="icon">
            @if (inputData.title?.toLowerCase().includes('error')) {
              <img [src]="icons.cloud_cross">
            }@else if (inputData.message?.toLowerCase().includes('notification has been enabled') || inputData?.push == 'push') {
            <span class="active-img-span">
              <img [src]="icons.anable_notification" alt="">
            </span>
          }@else if (inputData.message?.toLowerCase().includes('notification has been disabled')) {
            <span class="inactive-img-span">
              <img [src]="icons.disable_notification" alt="">
            </span>
          }@else{
            <img [src]="icons.toast_check_icon">
            }
          </span>
          <div class="msg-div">
            <div class="title">{{inputData?.title}}</div>
            <div class="msg" >{{inputData?.message}}</div>

          </div>
        </div>
        <img class="action-icon" (click)="closeToast()" [src]="icons.close_icon">
    
      </div>
    </div>
  </div>
} 