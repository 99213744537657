import { Routes } from '@angular/router';
import { FormComponent } from './components/form/form.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './services/auth/auth.guard';
import { DashboardComponent } from './auth/pages/dashboard/dashboard.component';
import { PreventAuthGuard } from './services/auth/authReroute.guard';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { DownloadAppComponent } from './pages/download-app/download-app.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { DeleteUserAccountComponent } from './pages/delete-user-account/delete-user-account.component';
import { PolicyAndPrivacyComponent } from './pages/policy-and-privacy/policy-and-privacy.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DownloadAppSigninComponent } from './pages/download-app-signin/download-app-signin.component';
const authRoute = () =>
  import('./auth/auth-routing.module').then((x) => x.AuthRoutingModule);
export const routes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'signin', pathMatch: 'full', component: SignInComponent },
  {
    path: 'verify-email/:userId/:activationCode/:role',
    pathMatch: 'full',
    component: VerifyEmailComponent,
  },
  {
    path: 'policy',
    pathMatch: 'full',
    component: PolicyAndPrivacyComponent,
  },
  {
    path: 'reset-password/:token',
    pathMatch: 'full',
    component: SetPasswordComponent,
  },
  { path: 'download-app', pathMatch: 'full', component: DownloadAppComponent },
  { path: 'download-app-signin', pathMatch: 'full', component: DownloadAppSigninComponent },
  {
    path: 'deleteuser',
    pathMatch: 'full',
    component: DeleteUserAccountComponent,
  },
  { path: '', loadChildren: authRoute },
  {
    path: '**',
    component: PageNotFoundComponent,

  },
];
