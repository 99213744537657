import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonWrapperComponent } from '../../components/button-wrapper/button-wrapper.component';
import { InputFieldsComponent } from '../../components/input-fields/input-fields.component';
import { Images } from '../../constant/image';
import { icons } from '../../constant/icons';

@Component({
  selector: 'app-download-app-signin',
  standalone: true,
  imports: [CommonModule, ButtonWrapperComponent, InputFieldsComponent, RouterModule],
  templateUrl: './download-app-signin.component.html',
  styleUrl: './download-app-signin.component.scss'
})
export class DownloadAppSigninComponent {
  public images = Images;
  public icons = icons;
  copyrightYear: any = new Date();

  constructor(private authService: AuthService, private router: Router,){
 
  }
}
