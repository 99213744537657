@if(signinPage){
  @if(showLoader){
    <app-loader></app-loader>
  }
<div class="signin-container">
    <div class="login-section">
        <img [src]="icons.rectangle1_bg" alt="" class="first-top left-imgbg">
        <img [src]="icons.rectangle2_bg" alt="" class="second-top left-imgbg">
        <div class="logo">
            <div class="logo-left">
                <img class="logoimg" [src]="images.logo" alt="">
                <span class="logoletter">LSRT</span>
            </div>
            <div class="logo-right">
                <img [src]="images.certified" alt="">
            </div>
        </div>
        <div class="form">
            <div class="welcome">
                <span class="welcometitle">
                    Welcome
                </span>
                <span class="welcomedetail">
                    Enter your details to continue
                </span>
            </div>
            <div class="form-input">
                <div class="input-field">
                    <app-input-fields type="email" placeholder="Email address" (keyup.enter)="signIn()" (fieldData)="getformdata($event, 'email')" [control]="signInApp.controls.email" >
                    </app-input-fields>
                    @if(invalidCred){
                        <small class="errorMessage">
                          <span>
                            <img [src]="icons.alert_circle" alt="">
                          </span>
                          <span>
                            {{ invalidCred }}
                          </span>
                        </small>
                    }
                </div>
                <div class="input-field">
                    <app-input-fields type="password" placeholder="Password" (keyup.enter)="signIn()
                    " (fieldData)="getformdata($event, 'password')" [control]="signInApp.controls.password" >
                    </app-input-fields>
                    @if(invalidCred){
                        <small class="errorMessage">
                          <span>
                            <img [src]="icons.alert_circle" alt="">
                          </span>
                          <span>
                            {{ invalidCred }}
                          </span>
                        </small>
                    }
                </div>
                <div class="forget-pass" (click)="resetPswd()">
                    <span class="forget-text">
                        Forgot password?
                    </span>
                </div>
                <div class="button">
                    <app-button-wrapper type="PRIMARY" widthsize="100%" (click)="signIn()">
                        <div class="button-detail" id="content">
                            <span class="btn-name">Sign in</span>
                            <img [src]="icons.arrow_circle_right_white" alt="">
                        </div>
                    </app-button-wrapper>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>
    <div class="info-section">
        <img [src]="icons.rectangle1_bg" alt="" class="first-top imgbg">
        <img [src]="icons.rectangle2_bg" alt="" class="second-top imgbg">
        <img [src]="icons.rectangle3_bg" alt="" class="first-bottom imgbg">
        <img [src]="icons.rectangle4_bg" alt="" class="second-bottom imgbg">
        <div class="display">
            @if(nav > 1){
                <img [src]="icons.nav_arrow_left" class="nav" alt="" (click)="navLink('left')">
            }@else {
                <img [src]="icons.nav_arrow_left" class="nav inv" alt="">
            }
            <!-- <div class="view"> -->
                @if(nav == 1){
                    <img [src]="images.elsrt_illus1" class="view" alt="images">
                }
                @if(nav == 2){
                    <img [src]="images.elsrt_illus2" class="view" alt="">
                }
                @if(nav == 3){
                <img [src]="images.elsrt_illus" class="view" alt="">
                }
            <!-- </div> -->
             @if(nav < 3){
                 <img [src]="icons.nav_arrow_right" class="nav" alt="" (click)="navLink('right')">
             }@else {
                <img [src]="icons.nav_arrow_left" class="nav inv" alt="">
            }
        </div>
        <div class="nav-info">
            @if(nav === 1){
                <span class="nav-title">
                    Overview
                </span>
                <span class="nav-detail">
                    View summary of your batch statuses.
                </span>
            }
            @if(nav === 2){
                <span class="nav-title">
                    Track batch samples
                </span>
                <span class="nav-detail">
                    Track the status of batches as they move from one facility to another, providing real-time updates on their journey and current status.
                </span>
            }
            @if(nav === 3){
                <span class="nav-title">
                    Reports
                </span>
                <span class="nav-detail">
                    See reports for batches and diseases.
                </span>
            }
        </div>
        <div class="nav-indicator">
            <div class="" [ngClass]="nav === 1? 'current-nav':'nav-elipse'"></div>
            <div class="" [ngClass]="nav === 2? 'current-nav':'nav-elipse'"></div>
            <div class="" [ngClass]="nav === 3? 'current-nav':'nav-elipse'"></div>
        </div>

    </div>
</div>
}@else if (resetPswdPage) {    
    <div class="reset-pswd-container">
        <div class="lft">
          <div class="header">
            <div class="lft-side">
              <img class="logoimg" [src]="images.logo" alt="">
              <span class="logo-title">LSRT</span>
            </div>
            <div class="right">
              <img [src]="images.certified" alt="">
            </div>
      
          </div>  
          @if (resetPasswordstep_1) {
              <div class="body">
                  <div class="upper-div">
                    <div class="bck-arr" (click)="resetPswd()">
                      <img [src]="icons.arrow_left" class="img" alt="">
                    </div>
                    <div class="title">Forgot Password?</div>
                    <div class="txt-content">
                      Please, enter your email address to receive your password reset instructions
                    </div>
          
                  </div>
                  <div class="lower-div">
                    <div class="field">
                      <app-input-fields type="email" placeholder="Email address"  (fieldData)="getformdata($event, 'rest-pswd')"></app-input-fields>
                      @if(!validationFlag || errMsg){
                        <div class="validation-div">
                          <img [src]="icons.alert_circle" alt="">
                          <span class="err-msg">{{errMsg  || 'Invalid email'}}</span>
                        </div>
                      }
                    </div>
                    <div class="btn">
                        <div class="button">
                          <app-button-wrapper type="PRIMARY" widthsize="100%" (click)="submitData()">
                            <div class="button-detail" id="content">
                                <span class="btn-name">Send reset link</span>
                                <img [src]="icons.arrow_circle_right_white" alt="">
                            </div>
                        </app-button-wrapper>
                        </div>
                    </div>
                  </div>
              </div>
          }
          @else if (resetPasswordstep_2) {
            <div class="body extra-css">
              <div class="step-2-upper-div">
                <div class="bck-arr" >
                  <img [src]="icons.mail" class="img" alt="">
                </div>
                <div class="title-2">Password reset link sent</div>
                <div class="txt-content center-txt">
                  You’ll receive an email containing instructions on how to reset your password
                </div>
              </div>
              <div class="lower-div">     
                <div class="hint">
                  <span class="sent">Sent to:</span>
                  <span class="email-txt">{{email}}</span>
                  <!-- <app-button-wrapper [disableButton]="true"  type="SECONDARY"   >
                    <div class="button-detail" id="content">
                        <span class="btn-name"> </span>
                    </div>
                </app-button-wrapper> -->
                </div>
              </div>
            </div>    
          }
          <div class="footer">
            <div class="content">
              (c) {{copyrightYear.getFullYear()}} - ELSRT
            </div>
            <div></div>
          </div>
          <img [src]="images.design_img" class="mobile-deisgn-img" alt="">
      
        </div>
        <div class="rite-side">
          <img [src]="images.design_img" class="deisgn-img" alt="">
        </div>
      </div>
}