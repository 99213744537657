<div class="signin-container">
    <div class="login-section">
        <img [src]="icons.rectangle1_bg" alt="" class="first-top left-imgbg">
        <img [src]="icons.rectangle2_bg" alt="" class="second-top left-imgbg">
        <div class="logo">
            <div class="logo-left">
                <img class="logoimg" [src]="images.logo" alt="">
                <span class="logoletter">LSRT</span>
            </div>
            <div class="logo-right">
                <img [src]="images.certified" alt="">
            </div>
        </div>
        <div class="form">
            <div class="welcome">
                <span class="welcometitle">
                    Account Setup
                </span>
                <span class="welcomedetail">
                    Enter your information to complete your account setup
                </span>
            </div>
            <div class="form-input">
                <div class="input-field">
                    <app-input-fields type="password" placeholder="Create password" (keyup.enter)="changepassword()
                    " (fieldData)="getformdata($event, 'password')" [control]="createPass.controls['password']"
                        errorMessageName="password">
                    </app-input-fields>
                </div>
                <div class="input-field">
                    <app-input-fields type="password" placeholder="Confirm password" (keyup.enter)="changepassword()
                    " (fieldData)="getformdata($event, 'confirmpassword')"
                        [control]="createPass.controls['confirmPassword']" errorMessageName="confirmPassword">
                    </app-input-fields>
                    @if(errorCred){
                    <small class="errorMessage">
                        <span>
                            <img [src]="icons.alert_circle" alt="">
                        </span>
                        <span>
                            {{ errorCred }}
                        </span>
                    </small>
                    }
                </div>
                <div class="button">
                    <app-button-wrapper type="PRIMARY" widthsize="100%" (click)="changepassword()">
                        <div class="button-detail" id="content">
                            <span class="btn-name">Complete Setup</span>
                            <img [src]="icons.arrow_circle_right_white" alt="">
                        </div>
                    </app-button-wrapper>
                </div>
            </div>

        </div>
        <div class="footer">
            <span class="copyright">
                (c) {{copyrightYear | date:"yyyy"}} - ELSRT
            </span>
            <span class="copyright pointer" routerLink="/policy">
                Privacy Policy
            </span>
        </div>

    </div>
    <div class="info-section">
        <img [src]="icons.rectangle1_bg" alt="" class="second-top imgbg">
        <img [src]="icons.rectangle2_bg" alt="" class="first-top imgbg">

    </div>
</div>