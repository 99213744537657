
export class SignInDto{
    public email!: string | null | undefined;
    public password!: string | null | undefined;
}

export class createPasswordDto{
    public token!: string | null | undefined;
    public newPassword!: string | null | undefined;
}

export class ForgotPasswordDTO{
    public email!: string;
    public replyUrl!: string;
}

export class ResetPasswordDTO{
    public newPassword!: string;
    public token!: string;
}

export class reasonForDeletionDto{
    public email!: string | null | undefined;
    public password!: string | null | undefined;
}
