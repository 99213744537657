import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { AuthService } from './services/auth/auth.service';
import { CommonModule, Location } from '@angular/common';
import { ExportModalComponent } from './components/export-modal/export-modal.component';
import { ExportService } from './services/export.service';
import { ToastComponent } from "./components/toast/toast.component";
import { ToastService } from './toast';
//import { SharedModule } from './shared/shared.module';
//import { DropdownOptionsComponent } from './components/dropdown-options/dropdown-options.component';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ExportModalComponent, ToastComponent],

  //imports: [RouterOutlet, SharedModule, DropdownOptionsComponent ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  exportData: any;
  title = 'elsrt-web';
  toastData!:Object;
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private exportService: ExportService,
    private toastService:ToastService
  ) {
    this.exportService.exportData$.subscribe((data) => {
      this.exportData = data;
    });

    this.toastService.toastData.subscribe((data:any)=>{
      this.toastData = data
    })
  }
  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      if (this.router.url === '/signin' || this.location.path() === '') {
        this.router.navigateByUrl('dashboard', { replaceUrl: true });
      }
    }
  }
}
