<!-- <p>sign-in works!</p> -->

<div class="signin-container">
    <div class="login-section">
        <img [src]="icons.rectangle1_bg" alt="" class="first-top left-imgbg">
        <img [src]="icons.rectangle2_bg" alt="" class="second-top left-imgbg">
        <div class="logo">
            <div class="logo-left">
                <img class="logoimg" [src]="images.logo" alt="">
                <span class="logoletter">LSRT</span>
            </div>
            <div class="logo-right">
                <img [src]="images.certified" alt="">
            </div>
        </div>
        <div class="form">
            <div class="activated">
                <img [src]="icons.activate_circle" alt="" class="mark">
                <div class="welcome">
                    <span class="welcometitle">
                        Account setup was successful
                    </span>
                    <span class="welcomedetail">
                        Login with your new credentials to access your account
                    </span>
                </div>
            </div>
            <div class="form-input">
                <span class="download-note">
                    Download the mobile app
                </span>
                <div class="download-img">
                    <img [src]="icons.apple_store" alt="">
                    <img [src]="icons.play_store" alt="">
                </div>

            </div>

        </div>
        <div class="footer">
            <span class="copyright">
                (c) {{copyrightYear | date:"yyyy"}} - ELSRT
            </span>
            <span class="copyright pointer" routerLink="/policy">
                Privacy Policy
            </span>
        </div>

    </div>
    <div class="info-section">
        <img [src]="icons.rectangle1_bg" alt="" class="first-top imgbg">
        <img [src]="icons.rectangle2_bg" alt="" class="second-top imgbg">
        <!-- <img src="../../../assets/icons/Rectangle3-bg.svg" alt="" class="first-bottom imgbg">
        <img src="../../../assets/icons/Rectangle4-bg.svg" alt="" class="second-bottom imgbg"> -->

    </div>
</div>