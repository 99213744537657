<div
  class="exportbody"
  [ngClass]="
    exportData?.position == 'leftTop'
      ? 'exportbody leftTop'
      : exportData?.position == 'rightTop'
      ? 'exportbody rightTop'
      : exportData?.position == 'leftBottom'
      ? 'exportbody leftBottom'
      : 'exportbody rightBottom'
  "
  *ngIf="exportData?.display"
>
@if(exportData?.loader == false){
    <div class="export-container">
      <div class="export-body">
        <span class="icon">
            @if(exportData?.title == 'Export Successful'){
                <img [src]="icons.activate_circle"/>
            }
            @else if(exportData?.title == 'Export Failed'){
                <img [src]="icons.deactivate_circle_slash"/>
            }
        </span>
        <div class="export-txt">
          <div class="txt-title">{{ exportData.title }}</div>
          <div class="txt-body">
            {{ exportData.message }}
          </div>
        </div>
      </div>
      <div class="close-div" (click)="closeExportModal()">
        <img [src]="icons.x" />
      </div>
    </div>
}@else if(exportData?.loader == true){
    <div class="export-container">
      <div class="export-body">
        <span class="icon-ellipse">
          <img [src]="icons.cloud_arrow_down" />
        </span>
        <div class="export-txt">
          <div class="txt-title">{{ exportData.title }}</div>
          <div class="txt-body">
            {{ exportData.message }}
          </div>
        </div>
      </div>
      <div class="close-div">
        <div class="loader"></div>
      </div>
    </div>
}
</div>
