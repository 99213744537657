import { Component, Input, OnChanges } from '@angular/core';
import { ToastDto } from '../../toast/toast-dto';
import { ToastService } from '../../toast/index';
import { CommonModule } from "@angular/common";
import { Images } from '../../constant/image';
import { icons } from '../../constant/icons';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class ToastComponent implements OnChanges{
  public images = Images;
  public icons = icons;
  @Input() inputData: any;
  public updatedToastData! : ToastDto;


  constructor(
    private toastService: ToastService,
  ) { }

  ngOnChanges(){
    this.updatedToastData = this.inputData;
  }

  closeToast(){
    this.toastService.clearMessage();
  }
}
