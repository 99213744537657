<div class="container">
  <div class="header">
    <div class="logo-left">
      <img class="logoimg" [src]="images.logo" alt="">
      <span class="logoletter">LSRT</span>
    </div>
    <div class="logo-right">
        <img [src]="images.certified" alt="">
    </div>  
  </div>
  <div class="body">
    <div class="number">404</div>
    <div class="content">
      <div class="txt-div">
        <div class="inner-div">
          <img [src]="icons.page_not_found_icon" alt="">
          <div class="title">
            A Map to Nowhere
          </div>

        </div>
        <div class="txt"></div>
        It seems your quest has led you to a page that’s off the map! Fear not, brave explorer. 
      </div>
        <app-button (click)="redirect()" label="Return to dashboard" type="PRIMARY"></app-button>
    </div>
  </div>
  <div class="footer">
    <div class="lft-side">&copy; {{date}}  - ELSRT</div>
    <div class="right-side">Privacy Policy</div>
  </div>

</div>